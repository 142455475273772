import React from "react";
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import Home from './view/Home/Home'
import Support from "./view/Support/Support";
import Contact from "./view/Contact/Contact";
import PageNotFound from "./view/PageNotFound/PageNotFound";
import MainContent from "./MainContent";
import TermsOfService from "./view/Legal/TermsOfService";
import PrivacyPolicy from "./view/Legal/PrivacyPolicy";

interface AppInterface {
    showHeader?: boolean
}

const App = (props: AppInterface) => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={"/"}>
                    <Route index element={<Home/>}/>
                    <Route path={"support"} element={<MainContent element={<Support/>}/>}/>
                    <Route path={"legal/terms-of-service"} element={<MainContent element={<TermsOfService/>}/>}/>
                    <Route path={"legal/privacy-policy"} element={<MainContent element={<PrivacyPolicy/>}/>}/>
                    <Route path={"contact"} element={<MainContent element={<Contact/>}/>}/>
                    <Route path={"*"} element={<MainContent element={<PageNotFound/>} hideHeader={true}/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
