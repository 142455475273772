import React from 'react';
import '../Home/Home.css';

function Contact() {
    document.title = "contact | empty monkey";

    return (
        <div className={"Contact-Component"}>
            <h1>Contact</h1>
            <p>
                We have email addresses! Send us <a href={"mailto:niklas@emptymonkey.com"}>email</a>!
            </p>
        </div>
    );
}

export default Contact;
