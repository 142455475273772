import React from 'react';
import '../Home/Home.css';

function TermsOfService() {
    document.title = "terms of service | empty monkey";

    return (
        <div className={"TermsOfService-Component"}>
            <h1>Terms of service</h1>
            <p>
                We will add the terms later, ok?
            </p>
        </div>
    );
}

export default TermsOfService;
