import React from 'react';
import './Footer.css'
import {Link, NavLink} from "react-router-dom";

interface FooterProps {
    separator?: string;
}

function Footer(props: FooterProps) {
    let separator = props.separator ?? " x ";

    return (
        <div className={"Footer-Component"}>
            <nav className={"Links"}>
                <NavLink to={"/"}>Home</NavLink>
                {separator}
                <NavLink to={"/legal/terms-of-service"}>Terms of service</NavLink>
                {separator}
                <NavLink to={"/legal/privacy-policy"}>Privacy policy</NavLink>
                {separator}
                <NavLink to={"/support"}>Support</NavLink>
                {separator}
                <NavLink to={"/contact"}>Contact</NavLink>
            </nav>
            <div className={"Copyright"}>
                © Empty Monkey - All Rights Reserved - You're still lovely &lt;3
            </div>
        </div>
    );
}

export default Footer;
