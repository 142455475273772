import React from "react";
import Footer from "./component/Footer/Footer";
import Header from "./component/Header/Header";

interface MainContentInterface {
    element: React.ReactElement
    hideHeader?: boolean;
    hideFooter?: boolean;
}

const MainContent = (props: MainContentInterface) => {
    let hideHeader = props.hideHeader ?? false;
    let hideFooter = props.hideFooter ?? false;
    
    return (
        <>
            {hideHeader || <Header/>}
            <div className={"Main"}>
                <div className={"Main-Content"}>
                    {props.element}
                </div>
            </div>
            {hideFooter || <Footer separator={" - "}/>}
        </>
    );
};

export default MainContent;
