import React from 'react';
import '../Home/Home.css';

function Support() {
    document.title = "support | empty monkey";

    return (
        <div className={"Support-Component"}>
            <h1>Support</h1>
            <p>Right now there's nothing really to support. :'&lt;</p>
        </div>
    );
}

export default Support;
