import React from 'react';
import '../Home/Home.css';

function PrivacyPolicy() {
    document.title = "privacy policy | empty monkey";

    return (
        <div className={"PrivacyPolicy-Component"}>
            <h1>Privacy policy</h1>
            <p>We will add the policy later, ok?</p>
        </div>
    );
}

export default PrivacyPolicy;
