import React from 'react';
import './Header.css'
import Logo from '../../img/monkey-svgrepo-com.svg';

function Header() {
    return (
        <div className={"Header-Component"}>
            <div className={"Logo"}>
                <img src={Logo} alt={"empty monkey logo"} />
            </div>
        </div>
    );
}

export default Header;
