import React from 'react';
import '../Home/Home.css';

function PageNotFound() {
    document.title = "oops | empty monkey";

    return (
        <div className={"PageNotFound-Component"}>
            <h1>The page you're looking for is not there</h1>
            <p>The devs are not going to get bananas tomorrow &lt;/3</p>
        </div>
    );
}

export default PageNotFound;
