import React from 'react';
import monkey from '../../img/monkey-svgrepo-com.svg'
import './Home.css';
import Footer from "../../component/Footer/Footer";

function Home() {

    document.title = "hello | empty monkey";

    return (
        <>
            <div className={"App"}>
                <div className={"Container"}>
                    <div className={"Inner-Container"}>
                        <div className={"Left-panel"}>
                            <img src={monkey} className={"App-logo"} alt="Empty Monkey"/>
                        </div>
                        <div className={"Right-panel"}>
                            <p>
                                Hello
                            </p>
                            <p>
                                I think you're lovely.
                            </p>
                            <p>
                                Love, <br/>
                                The Monkey
                            </p>
                            <a
                                className="App-link"
                                href="https://reactjs.org"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                            </a>
                        </div>
                        <div className={"Break"}></div>
                    </div>
                </div>
            </div>
            <Footer separator={" - "}/>
        </>
    );
}

export default Home;
